(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:ComfortCardItemListCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('ComfortCardItemListCtrl', ComfortCardItemListCtrl);

  function ComfortCardItemListCtrl(FormControl, $mdDialog, $document, orderType, Cart, _, $q, $scope, $rootScope, $translate, isAuthenticated, OrderService, user) {
    var vm = this
      , formControl = new FormControl()
      , cart = new Cart()
      , OrderItem = cart.getOrderItem()
      , deferred = $q.defer();

    cart.id = orderType;
    OrderItem = cart.getOrderItem();
    vm.order = {};
    vm.orderType = orderType;

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.refreshOrderItemList = function refreshOrderItemList() {
      vm.order.items = OrderItem.query();
      OrderService.updateCart();
    };

    vm.refreshOrderItemList();

    $rootScope.$on('itemList.refresh', vm.refreshOrderItemList);

    $rootScope.$on('itemList.openItemDialog', function (e, item) {
      vm.openItemDialog(undefined, item);
    });

    vm.getSum = function getSum(type) {
      var coll = vm.order.items.map(function (item) {
        return type === 'loadAmount' ? item.quantity * item.loadAmount : item.quantity;
      });

      return _.reduce(coll, function (sum, acc) {
        return sum + acc;
      }, 0);
    };

    vm.openItemDialog = function openItemDialog(index, rawItem) {
      var item = !_.isUndefined(rawItem) ? new OrderItem(rawItem) : _.defaultsDeep(new OrderItem(), vm.order.items[index]);
      $mdDialog.show({
        controller: 'ComfortCardItemDialogCtrl',
        controllerAs: 'comfortCardItemDialog',
        templateUrl: 'wb-order/views/comfort-card-item-dialog.tpl.html',
        bindToController: true,
        locals: {
          item: item,
          orderType: orderType,
          user: user
        },
        parent: angular.element($document.body),
        clickOutsideToClose: true,
        focusOnOpen: false
      });
    };

    vm.editItem = function editItem(index) {
      vm.openItemDialog(index);
    };

    vm.deleteItem = function deleteItem(index) {
      var confirm = $mdDialog.confirm({ focusOnOpen: false })
        .parent(angular.element($document.body))
        .content($translate.instant('order.itemDialog.confirmDelete'))
        .ok($translate.instant('order.itemDialog.delete'))
        .cancel($translate.instant('order.itemDialog.cancel'));

      $mdDialog
        .show(confirm)
        .then(function () {
          var orderItem = _.defaultsDeep(new OrderItem(), vm.order.items[index]);

          deferred = $q.defer();

          formControl.setSuccessText('order.unique.toast.delete');
          formControl
            .process(deferred.promise)
            .then(function () {
              $rootScope.$broadcast('itemList.refresh');
            });
          orderItem.$delete(deferred.resolve, deferred.reject);
        });
    };
  }
}());
